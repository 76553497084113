const loadTera = () => {
  const existingScript = document.getElementById('teraTag');
  const host = (typeof window !== 'undefined') ? window.location.hostname : '';
  if (!existingScript && host.indexOf('localhost') === -1) {
    const script = document.createElement('script');
    script.src = `https://pl25857887.effectiveratecpm.com/f9df12bb32569a9c2176a32ee7d1cb8b/invoke.js`;
    script.id = 'teraTag';
    script.async = 'async';
    script.dataset.cfasync = 'false';
    script.onload = () => script.setAttribute('data-loaded', true); 
    document.body.appendChild(script);
  }
};

const checkIsLoad = () => {
  let script   = document.getElementById('teraTag');
  let isLoaded = script && script.getAttribute('data-loaded') === 'true';
  // console.log(isLoaded);
  return isLoaded;
};

export default loadTera;
export {checkIsLoad};