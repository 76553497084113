import React, { Component } from 'react';
import Helmet from 'react-helmet';
//import { checkIsLoad } from '../common/loadSuperior';
import loadTera, { checkIsLoad } from '../common/loadTera';

class Clock extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        loadTera();
        let urlToRedirect = this.state.currSeo.urlToSend ? this.state.currSeo.urlToSend : 'https://timenowin.net';
        let t1 = setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        let t2 = setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        let t3 = setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        let tDef = setTimeout(() => window.location = urlToRedirect, 8000); // max to wait

        // check when ads as printed to
        var observer = new MutationObserver(function (mutations) {
            if (checkIsLoad()) {
                // console.log("It's in the DOM!");
                clearTimeout(t1);
                clearTimeout(t2);
                clearTimeout(t3);
                clearTimeout(tDef);
                setTimeout(() => document.getElementById('wait').innerText = 'wait.', 1500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait...', 2600);
                setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 4500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait........', 6500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait...........', 7500);
                setTimeout(() => window.location = urlToRedirect, 7500);

                observer.disconnect();
            }
        });
        observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });

    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Online Clock: Full Screen - Digital/Analog - Night mode",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.onclock.net",
            "keywords": "Online Clock, Clock, Free Online Clock, Large Online Clock, Large Clock, Full Screen Online Clock, Free Large Clock, Free FullScreen Online Clock, Free Full Screen Clock, FullScreen Clock, online clock,clock,digital clock,analog clock, tab",
            "description": "Online Clock - A Free Online Clock! Free, Easy To Use LARGE Full Screen Online Clock! exact time with seconds on the full screen."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Online Clock: Full Screen - Digital/Analog - Night mode"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "Online Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch."
        ldJson.url = 'https://www.onclock.net' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta name="keywords" content={ldJson.keywords} />
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <div id="container-f9df12bb32569a9c2176a32ee7d1cb8b"></div>
                <ul>
                    {this.props.seoLinks.map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>{`Everybody needs a ${this.state.currSeo.anchorText} at some point -- and there's never one around!`}<br />
                    {`Well... Now there is! ${this.state.currSeo.anchorText}!`}<br />{` A Simple, Fast and easy to use ${this.state.currSeo.anchorText} always available when you
                    need it.`}</h2>
            </React.Fragment>
        )
    }


}

export default Clock;